export default [
    {
        path: '/clients',
        name: 'clients',
        component: () => import('@/views/cromis/clients/Client.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Clients',
                }
            ],
        }
    },
    {
        path: '/locations',
        name: 'locations',
        component: () => import('@/views/cromis/locations/Location.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Locations',
                }
            ],
        }
    },
    {
        path: '/buyers',
        name: 'buyers',
        component: () => import('@/views/cromis/buyers/Buyer.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Buyers',
                }
            ],
        }
    },

    {
        path: '/clerks',
        name: 'clerks',
        component: () => import('@/views/cromis/clerks/Clerks.vue'),
        meta: {
            pageTitle: 'Clerks',
            breadcrumb: [
                {
                    text: 'Clerks',
                }
            ],
        }
    },
    {
        path: '/classifire',
        name: 'classifire',
        component: () => import('@/views/cromis/classifire/Classifier.vue'),
        meta: {
            pageTitle: 'Classifier',
            breadcrumb: [
                {
                    text: 'Classifires',
                }
            ],
        }
    },
    {
        path: '/markets',
        name: 'markets',
        component: () => import('@/views/cromis/markets/Market.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Market Centers',
                }
            ],
        }
    },
    {
        path: '/seasons',
        name: 'seasons',
        component: () => import('@/views/cromis/season/Season.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Seasons',
                },
            ],
        },
    },
    {
        path: '/societies',
        name: 'societies',
        component: () => import('@/views/cromis/society/Society.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Societies',
                },
            ],
        },
    },
    {
        path: '/growers',
        name: 'growers',
        component: () => import('@/views/cromis/grower/Grower.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Growers',
                },
            ],
        },
    },
    {
        path: '/growers/:id',
        name: 'growers',
        component: () => import('@/views/cromis/grower/GrowerView.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Grower Facilities',
                },
            ],
        },
    },
    {
        path: '/sectors',
        name: 'sectors',
        component: () => import('@/views/cromis/sectors/Sector.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Sectors',
                }
            ],
        }
    },
    {
        path: '/clubs',
        name: 'clubs',
        component: () => import('@/views/cromis/clubs/Club.vue'),
        meta: {
            pageTitle: 'Registration',
            breadcrumb: [
                {
                    text: 'Clubs',
                }
            ],
        }
    },
    {
        path: '/countries',
        name: 'countries',
        component: () => import('@/views/cromis/countries/Country.vue'),
        meta: {
            pageTitle: 'Registation',
            breadcrumb: [
                {
                    text: 'Countries',
                }
            ],
        }
    },
    {
        path: '/companies',
        name: 'companies',
        component: () => import('@/views/cromis/companies/Company.vue'),
        meta: {
            pageTitle: 'Registation',
            breadcrumb: [
                {
                    text: 'Companies',
                }
            ],
        }
    },
    {
        path: '/grades_group',
        name: 'grades_group',
        component: () => import('@/views/cromis/grades/grade_groups/GradeGroups.vue'),
        meta: {
            pageTitle: 'Grade Groups',
            breadcrumb: [
                {
                    text: 'Grade Grades',
                }
            ],
        }
    },
    {
        path: '/crop_grades',
        name: 'crop_grades',
        component: () => import('@/views/cromis/grades/crop_grades/Grade.vue'),
        meta: {
            pageTitle: 'Registation',
            breadcrumb: [
                {
                    text: 'Crop Grades',
                }
            ],
        }
    },
    {
        path: '/grower_inputs',
        name: 'grower_inputs',
        component: () => import('@/views/cromis/inputs/Input.vue'),
        meta: {
            pageTitle: 'Registation',
            breadcrumb: [
                {
                    text: 'Grower Inputs',
                }
            ],
        }
    },

    // Market Sales
    {
        path: '/sales',
        name: 'sales',
        component: () => import('@/views/cromis/sales/sale/Sale.vue'),
        meta: {
            pageTitle: 'Market Sales',
            breadcrumb: [
                {
                    text: 'Register Sales',
                }
            ],
        }
    },
    {
        path: '/sale_close',
        name: 'sale_close',
        component: () => import('@/views/cromis/sales/sale_close/SaleClose.vue'),
        meta: {
            pageTitle: 'Market Sales',
            breadcrumb: [
                {
                    text: 'Sale Close',
                }
            ],
        }
    },
    {
        path: '/agreements',
        name: 'agreements',
        component: () => import('@/views/cromis/sales/agreement/Agreement.vue'),
        meta: {
            pageTitle: 'Market Sales',
            breadcrumb: [
                {
                    text: 'Purchase Contracts',
                }
            ],
        }
    },
    {
        path: '/tickets',
        name: 'tickets',
        component: () => import('@/views/cromis/sales/tickets/Ticket.vue'),
        meta: {
            pageTitle: 'Market Sales',
            breadcrumb: [
                {
                    text: 'Ticket Capturing',
                }
            ],
        }
    },

    {
        path: '/requisitions',
        name: 'requisitions',
        component: () => import('@/views/cromis/sales/requisition/Requisition.vue'),
        meta: {
            pageTitle: 'Requisitions',
            breadcrumb: [
                {
                    text: 'Requisition Details',
                }
            ],
        }
    },

    {
        path: '/receive_pcn',
        name: 'receive_pcn',
        component: () => import('@/views/cromis/sales/receive_pcn/ReceivePcn.vue'),
        meta: {
            pageTitle: 'PCN',
            breadcrumb: [
                {
                    text: 'Receive PCN',
                }
            ],
        }
    },
    {
        path: '/sale_documents',
        name: 'sale_documents',
        component: () => import('@/views/cromis/sales/sales_documents/SalesDocuments.vue'),
        meta: {
            pageTitle: 'Sales Documents',
            breadcrumb: [
                {
                    text: 'Print',
                }
            ],
        }
    },


    //Inventory Menus
    {
        path: '/boxes',
        name: 'boxes',
        component: () => import('@/views/cromis/inventory/box/Box.vue'),
        meta: {
            pageTitle: 'Boxes',
            breadcrumb: [
                {
                    text: 'Boxes Information',
                }
            ],
        }
    },

    {
        path: '/recieve_box',
        name: 'recieve_box',
        component: () => import('@/views/cromis/inventory/ticket_box/TicketBox.vue'),
        meta: {
            pageTitle: 'Recieve Ticket Box',
            breadcrumb: [
                {
                    text: 'Ticket Boxes Information',
                }
            ],
        }
    },

    // Transportation Menus
    {
        path: '/transporters',
        name: 'transporters',
        component: () => import('@/views/cromis/transportations/transporter/Transporter.vue'),
        meta: {
            pageTitle: 'Transportation',
            breadcrumb: [
                {
                    text: 'Transporter Information',
                }
            ],
        }
    },

    {
        path: '/fleets',
        name: 'fleets',
        component: () => import('@/views/cromis/transportations/fleet/Fleet.vue'),
        meta: {
            pageTitle: 'Fleets',
            breadcrumb: [
                {
                    text: 'Fleets Information',
                }
            ],
        }
    },

    {
        path: '/drivers',
        name: 'drivers',
        component: () => import('@/views/cromis/transportations/driver/Driver.vue'),
        meta: {
            pageTitle: 'Driver',
            breadcrumb: [
                {
                    text: 'Drivers Information',
                }
            ],
        }
    },



    // Warehouse Menus

    {
        path: '/storage_layouts',
        name: 'storage_layouts',
        component: () => import('@/views/cromis/warehouse/storage_layouts/StorageLayouts.vue'),
        meta: {
            pageTitle: 'Storage Layout',
            breadcrumb: [
                {
                    text: 'Storage Layout',
                }
            ],
        },
    },

    {
        path: '/warehouses',
        name: 'warehouses',
        component: () => import('@/views/cromis/warehouse/warehouse/WarehouseNew.vue'),
        meta: {
            pageTitle: 'Warehouses',
            breadcrumb: [
                {
                    text: 'Warehouse Information',
                }
            ],
        },
    },

    {
        path: '/warehouses/:id',
        name: 'warehouses',
        component: () => import('@/views/cromis/warehouse/warehouse/WarehouseView.vue'),
        meta: {
            pageTitle: 'Stack Management',
            breadcrumb: [
                {
                    text: 'Stack Management',
                },
            ],
        },
        props: true 
    },

    {
        path: '/routes',
        name: 'routes',
        component: () => import('@/views/cromis/warehouse/route/Route.vue'),
        meta: {
            pageTitle: 'Routes',
            breadcrumb: [
                {
                    text: 'Routes Information',
                }
            ],
        }
    },


        // Green Shipping Menus
        {
            path: '/long_haul',
            name: 'long_haul',
            component: () => import('@/views/cromis/green_shipping/long_haul/LongHaul.vue'),
            meta: {
                pageTitle: 'Long Haul TF',
                breadcrumb: [
                    {
                        text: 'Long Haul Information',
                    }
                ],
            }
        },
        {
            path: '/short_haul',
            name: 'short_haul',
            component: () => import('@/views/cromis/green_shipping/short_haul/ShortHaul.vue'),
            meta: {
                pageTitle: 'Short Haul TF',
                breadcrumb: [
                    {
                        text: 'Short Haul Information',
                    }
                ],
            }
        },
        {
            path: '/bulk_shipment',
            name: 'bulk_shipment',
            component: () => import('@/views/cromis/green_shipping/bulk_shipment/BulkShipment.vue'),
            meta: {
                pageTitle: 'Bulk Transfer',
                breadcrumb: [
                    {
                        text: 'Bulk Transfer',
                    }
                ],
            }
        },

    // Dry Shipping Menus
    {
        path: '/shipments',
        name: 'shipments',
        component: () => import('@/views/cromis/shipping/shipments/Shipment.vue'),
        meta: {
            pageTitle: 'Dry Shipping',
            breadcrumb: [
                {
                    text: 'Shipment Information',
                }
            ],
        }
    },
    {
        path: '/consignments',
        name: 'consignments',
        component: () => import('@/views/cromis/shipping/consignments/Consignment.vue'),
        meta: {
            pageTitle: 'Dry Shipping',
            breadcrumb: [
                {
                    text: 'Consignments',
                }
            ],
        }
    },
    {
        path: '/ports',
        name: 'ports',
        component: () => import('@/views/cromis/shipping/ports/Port.vue'),
        meta: {
            pageTitle: 'Dry Shipping',
            breadcrumb: [
                {
                    text: 'Ports',
                }
            ],
        }
    },
    {
        path: '/vessels',
        name: 'vessels',
        component: () => import('@/views/cromis/shipping/vessels/Vessel.vue'),
        meta: {
            pageTitle: 'Dry Shipping',
            breadcrumb: [
                {
                    text: 'Vessels',
                }
            ],
        }
    },

    {
        path: '/groups',
        name: 'groups',
        component: () => import('@/views/cromis/role/Group.vue'),
        meta: {
            pageTitle: 'System Security',
            breadcrumb: [
                {
                    text: 'User Groups',
                },
            ],
        },
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/cromis/users/User.vue'),
        meta: {
            pageTitle: 'System Security',
            breadcrumb: [
                {
                    text: 'Users Accounts',
                }
            ],
        }
    },
    {
        path: '/projects',
        name: 'projects',
        component: () => import('@/views/cromis/visitings/projects/Project.vue'),
        meta: {
            pageTitle: 'Field Vistings',
            breadcrumb: [
                {
                    text: 'Projects',
                }
            ],
        }
    },
    {
        path: '/questionnaires',
        name: 'questionnaires',
        component: () => import('@/views/cromis/visitings/questionnaires/Questionnaire.vue'),
        meta: {
            pageTitle: 'Field Vistings',
            breadcrumb: [
                {
                    text: 'Questionnaires',
                }
            ],
        }
    },
    {
        path: '/questions',
        name: 'questions',
        component: () => import('@/views/cromis/visitings/questions/Question.vue'),
        meta: {
            pageTitle: 'Field Vistings',
            breadcrumb: [
                {
                    text: 'Questions',
                }
            ],
        }
    },

    // Reports
    {
        path: '/village_sales',
        name: 'village_sales',
        component: () => import('@/views/cromis/reports/village_sale/VillageSales.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Taarifa ya Soko',
                }
            ],
        }
    },
    {
        path: '/grower_voucher',
        name: 'grower_voucher',
        component: () => import('@/views/cromis/reports/voucher/GrowerVoucher.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Hati ya Mauzo ya Mkulima',
                }
            ],
        }
    },
    {
        path: '/purchase_contracts',
        name: 'purchase_contracts',
        component: () => import('@/views/cromis/reports/pcns/PurchaseContract.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Purchase Contracts Notes',
                }
            ],
        }
    },
    {
        path: '/sale_summary',
        name: 'sale_summary',
        component: () => import('@/views/cromis/reports/sale_summary/SaleSummary.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Market Sale Summary',
                }
            ],
        }
    },
    {
        path: '/attachments',
        name: 'attachments',
        component: () => import('@/views/cromis/reports/attachments/Attachment.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Shipping Attachments',
                }
            ],
        }
    },
    {
        path: '/agronomy_extracts',
        name: 'agronomy_extracts',
        component: () => import('@/views/cromis/reports/agronomy/AgronomyExtracts.vue'),
        meta: {
            pageTitle: 'Reports',
            breadcrumb: [
                {
                    text: 'Agronomy Extracts',
                }
            ],
        }
    },
];
